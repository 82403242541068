/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  perfil: {},
  hasPayementMethod: false,
  userSubscriptions: [],
  userSubscriptionsLoading: false,
  userCancelSubscripptionLoading: {},
  UpdatePerfilUserLoading: false,
  userperfilLoading: false,
};
export const userPerfil = createAsyncThunk(
  'subscriptions/userPerfil',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users/gestor_info_contact`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const perfil = await response.data;
      return perfil.contact;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.data.message);
    }
  }
);

export const subcribedPlanUSer = createAsyncThunk(
  'subscriptions/subcribedPlanUSer',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users/gestor_contact_plans_subscriptions`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const subscripcion = await response.data;
      return subscripcion.subscriptions;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.data.message);
    }
  }
);

export const UnsubcribedPlanUSer = createAsyncThunk(
  'subscriptions/UnsubcribedPlanUSer',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/users/gestor_info_unSubscriptionPlan?plans_id=${id}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const plan = await response.data;

      return plan;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.data.message);
    }
  }
);

export const reactivatePlanUSer = createAsyncThunk(
  'subscriptions/reactivatePlanUSer',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/users/gestor_info_reactivate_subscription_plan?plans_id=${id}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const plan = await response.data;

      return plan;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.data.message);
    }
  }
);

export const UpdatePerfilUser = createAsyncThunk(
  'subscriptions/UpdatePerfilUser',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        ``,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const perfil = await response.data;
      return perfil.contact;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.data.message);
    }
  }
);

export const perfilUserSlice = createSlice({
  name: 'subcriptions',
  initialState,
  reducers: {},
  extraReducers: {
    [UnsubcribedPlanUSer.pending]: (state, action) => {
      state.userCancelSubscripptionLoading[action.meta.arg] = true; // Comienza la carga para esta suscripción específica
    },
    [UnsubcribedPlanUSer.fulfilled]: (state, action) => {
      state.userCancelSubscripptionLoading[action.meta.arg] = false; // Termina la carga para esta suscripción específica
      const subscription = state.userSubscriptions.find(sub => sub.subscription_id === action.meta.arg);
      if (subscription) {
        subscription.status = 'cancelled';
      }
    },
    [UnsubcribedPlanUSer.rejected]: (state, action) => {
      state.userCancelSubscripptionLoading[action.meta.arg] = false; // Termina la carga para esta suscripción específica
    },

    [subcribedPlanUSer.pending]: (state) => {
      state.userSubscriptionsLoading = true;
    },
    [subcribedPlanUSer.fulfilled]: (state, action) => {
      state.userSubscriptions = action.payload;
      state.userSubscriptionsLoading = false;
    },
    [subcribedPlanUSer.rejected]: (state) => {
      state.userSubscriptionsLoading = false;
    },
    [userPerfil.pending]: (state) => {
      state.userperfilLoading = true;
    },
    [userPerfil.fulfilled]: (state, action) => {
      state.perfil = action.payload;
      state.userperfilLoading = false;
      state.hasPayementMethod = action.payload?.cards?.length !== 0;
    },
    [userPerfil.rejected]: (state) => {
      state.userperfilLoading = false;
    },
    [UpdatePerfilUser.pending]: (state) => {
      state.UpdatePerfilUserLoading = true; 
    },
    [UpdatePerfilUser.fulfilled]: (state, action) => {
      state.perfil = action.payload;
      state.UpdatePerfilUserLoading = false;
    },
    [UpdatePerfilUser.rejected]: (state) => {
      state.UpdatePerfilUserLoading = false; 
    },
  },
});

export default perfilUserSlice.reducer;
